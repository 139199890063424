@keyframes example {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}

.line {
    width: 100%;
    animation-name: example;
    animation-duration: 4s;
  }